<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>


      <v-data-table
        :headers="headers"
        :items-per-page2="10"
        :items="agencies"
        :loading="loading"
        :footer-props="{showFirstLastPage: true,}"
        :server-items-length="total"
        :options.sync="options"
        @update:options="refreshTable"
      >

        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="info"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span> {{ $t("agencies.edit") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="error"
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> {{ $t("agencies.delete") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import {ObjectToQuery} from "@/plugins/helper";

export default {
  components: {ConfirmDialog, Dialog},
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      options: {},
      total: 0,
      agencies: [],
      editedItem: {
        id: 0,
        title: null,
        governorates: [],
        directorates: [],
      },
      defaultItem: {
        id: 0,
        title: null,
        governorates: [],
        directorates: [],
      },
      resetValidation: 0,
    };
  },
  computed: {
    headers() {
      return [
        {text: this.$t("agencies.title"), value: "title"},
        {text: "", value: "actions"},
      ];
    },
  },
  created() {
    if (!this.$store.getters.isInRole(101)) {
      this.$router.push({name: "notauthorize"});
    }
    this.loading = true;
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search: {
      handler() {
        this.refreshTable();
      }
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.agencies.indexOf(item);
      this.editedItem = {
        ...item,
        governorates: item.governorates.map(item => item.id),
        directorates: item.directorates.map(item => item.id),
      };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.agencies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const deleteItem = this.agencies[this.editedIndex];
      this.$axios
          .delete("agencies/" + deleteItem.id)
          .then(() => {
            this.refreshTable();
          });

      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    strip_html(string) {
      string = string.replace(/<(?:.|\n)*?>/gm, '');
      if (string.length > 50) {
        string = string.substr(0, 50) + '...';
      }
      return string;
    },
    refreshTable() {
      this.loading = true;
      let conditions = {
        ...this.options,
        search: this.search
      };
      const query = ObjectToQuery(conditions);
      this.$axios
          .get(`agencies/?${query}`, {noToast: true, failureToast: true})
          .then((response) => {
            this.agencies = response.data.data.data;
            this.total = response.data.data.total;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
