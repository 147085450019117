<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
                (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("agencies.index")
              }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.title"
                  :rules="[rulesWithLength.required]"
                  :label="$t('agencies.title')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  chips
                  multiple
                  :clearable="true"
                  :deletable-chips="true"
                  :items="governorates"
                  hide-details
                  v-model="editedItem.governorates"
                  @change="doSearch"
                  :label="$t('governorates')"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  chips
                  multiple
                  :clearable="true"
                  :deletable-chips="true"
                  hide-details
                  :items="directorates"
                  v-model="editedItem.directorates"
                  @change="doSearch"
                  :label="$t('directorates.directorates')"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid || submitLoading"
            :loading="submitLoading"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

export default {
  props: [
    "onSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
  ],
  data() {
    return {
      valid: true,
      isLoading: false,
      search: "",
      loaded: false,
      submitLoading: false,
      directorates: [],
      governorates: [],
      schools: [],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        image: (value) => !!value || this.$t("ThisFieldIsRequired"),
        length: (value) => value.length < 255 || this.$t("ThisFieldIsRequired"),
      },
      rulesWithLength: {
        required: (value) =>
            (value != null && value.length < 255) ||
            this.$t("ThisFieldIsRequired"),
      },
    };
  },

  watch: {
    resetValidation() {
      this.resetForm();
    },
    search: {
      handler() {
        this.doSearch();
      }
    },
  },
  async mounted() {
    this.$store
        .dispatch("GetUserDirectorates")
        .then((data) => {
          this.directorates = data;
        })
        .finally(() => {
          this.loading = false;
        });

    this.$store
        .dispatch("GetUserGovernorates")
        .then((data) => {
          this.governorates = data;
        })
        .finally(() => {
          this.loading = false;
        });

  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.submitLoading = true;
        const payload = new FormData();
        console.log(this.editedItem.image);
        payload.append('title', this.editedItem.title);

        this.editedItem.governorates.forEach(item => payload.append('governorates[]', item));
        this.editedItem.directorates.forEach(item => payload.append('directorates[]', item));
        if (this.editedIndex > -1) {
          this.$axios
              .post(
                  "agencies/" + this.editedItem.id,
                  payload
              )
              .then((response) => {
                if (response.data.status === "Successful") {
                  this.onClose();
                  this.$emit("refreshTable");
                }
              })
              .finally(() => {
                this.submitLoading = false;
              })
        } else {
          this.$axios
              .post("agencies", payload)
              .then((response) => {
                if (response.data.status === "Successful") {
                  this.onClose();
                  this.$emit("refreshTable");
                }
              })
              .finally(() => {
                this.submitLoading = false;
              })
        }
      }
    },
  },
};
</script>

